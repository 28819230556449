<template>
  <b-modal
    id="autofix-modal"
    centered
    @hide="closeModal"
  >
    <template #modal-header>
      <h5>Период гашения</h5>
      <b-button
        class="close"
        @click="hideModal"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>
    <div class="ext-caption mb-3">
      <img
        src="/img/icons/info_blue.svg"
        class="mr-2"
      />
      <span class="text-dark">Укажите через какой период автоматически гасить </span>
    </div>
    <label for="period-input">Период, дней</label>
    <b-form-input
      id="period-input"
      :value="mercury?.settings?.auto_fix ?? ''"
      type="number"
      @input="setInput"
    />
    <template #modal-footer>
      <div style="display: flex; flex-direction: row; flex: 1">
        <b-button
          class="m-0 p-0"
          variant="primary"
          style="flex: 1"
          :disabled="!period?.length"
          @click="setFix"
        >
          <span class="m-2">Сохранить</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  export default {
    name: 'AutofixModal',
    props: {
      mercury: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        period: ''
      }
    },
    methods: {
      hideModal() {
        this.$bvModal.hide('autofix-modal')
      },
      closeModal() {
        this.$emit('closeModal')
      },

      setInput(val) {
        this.period = val
      },
      setFix() {
        this.$emit('set-fix', this.period)
      }
    }
  }
</script>

<style scoped>
  .ext-caption {
    display: flex;
    padding: 16px;
    align-items: center;
    background: #eef9ff;
    border-left: 3px solid #99daff;
    border-radius: 4px;
  }
</style>
