var render = function render(){
  var _vm$items$;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.items.length > 0 ? _c('div', {
    staticClass: "b-table__navbar"
  }, [_c('img', {
    staticClass: "cursor",
    attrs: {
      "src": "/img/icons/table/icon_checked.svg",
      "alt": ""
    },
    on: {
      "click": _vm.clear
    }
  }), _vm._v(" " + _vm._s(_vm.items.length) + " " + _vm._s(_vm.item_name) + " "), _c('div', {
    staticClass: "b-btns mt-0"
  }, [_c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.changeStatus
    }
  }, [_vm._v(" Погасить ")]), _vm.items.length === 1 ? _c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.bind
    }
  }, [_vm._v(" " + _vm._s((_vm$items$ = _vm.items[0]) !== null && _vm$items$ !== void 0 && _vm$items$.product.product ? 'Отвязать' : 'Привязать') + " ")]) : _vm._e()], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }