import { ProductModel } from '@/models/product.model'

export class MercuryProductModel {
  constructor(request = {}) {
    this.id = request?.id
    this.product = request?.product ? new ProductModel(request.product) : null
    this.productItem_name = request?.productItem_name
    this.productItem_code = request?.productItem_code
    this.original_product = request?.original_product ? new ProductModel(request?.original_product) : null
  }
}
