<template>
  <section id="mercury-inner">
    <b-sidebar
      id="mercury-sidebar"
      v-model="show_sb"
      title="Меркурий"
      right
      backdrop
      shadow
      :visible="show_sb"
      @shown="setInt"
    >
      <template #header="{ hide }">
        Меркурий
        <img
          class="close-sidebar cursor"
          src="/img/integrations/icon-sidebar-close.svg"
          alt=""
          @click="hide"
        />
      </template>

      <div
        v-if="is_registered === null"
        class="sidebar-block info"
      >
        <p>
          Система предназначена для электронной сертификации и обеспечения прослеживаемости поднадзорных
          государственному ветеринарному надзору грузов при их производстве, обороте и перемещении по территории
          Российской Федерации в целях создания единой информационной среды для ветеринарии, повышения биологической и
          пищевой безопасности.
        </p>
        <div class="info__detail">
          <img
            src="/img/integrations/icon-info-blue.svg"
            alt=""
          />
          <router-link to="#">Подробная справка</router-link>
          по интеграции
        </div>
      </div>
      <div
        v-if="is_registered === null"
        class="token"
      >
        <div class="center p-5">
          <h4 class="pb-0 mb-0">Вы зарегистрированы в системе “Меркурий”?</h4>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
          <b-button
            variant="primary"
            class="cursor p-4"
            style="font-size: 16px"
            @click="is_registered = true"
          >
            Да, есть аккаунт
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-3 p-4"
            style="font-size: 16px"
            @click="is_registered = false"
          >
            Нет аккаунта
          </b-button>
        </div>
      </div>
      <div
        v-if="is_registered && step === 0"
        style="display: flex; flex-direction: column; flex: 1"
      >
        <h5 class="p-4 mb-0">
          Укажите данные Ветис.API которые вам приходили по Email с адреса no-reply@vetrf.ru от системы Меркурий
        </h5>
        <div
          class="p-3"
          style="padding-top: 0 !important"
        >
          <label> Login </label>
          <b-form-input
            v-model="login"
            class="mb-3"
            type="text"
            autocomplete="new-password"
          />
          <label> Password </label>
          <b-form-input
            v-model="password"
            class="mb-3"
            type="password"
            autocomplete="new-password"
          />
          <label> APIkey </label>
          <b-form-input
            v-model="api_key"
            class="mb-3"
          />
          <label> IssuerID </label>
          <b-form-input
            v-model="issuer_id"
            class="mb-3"
          />
        </div>
        <div
          v-if="error"
          class="mt-auto p-3 error-bottom"
        >
          <img
            src="/img/icons/warning-white.svg"
            alt="warn"
            class="mr-2"
          />
          Ошибка при подключении
        </div>
      </div>
      <div v-if="step === 1">
        <div class="sidebar-block">
          <div class="block-title">Сопоставьте магазины Энтерсайт с магазинами Меркурий</div>
          <div class="b-select">
            <div class="b-select__title">
              <div class="item">Магазин Энтерсайт</div>
              <div class="item">Магазин Меркурий</div>
            </div>
            <div
              v-for="(item, index) in branch_mapping"
              :key="index"
              class="b-select__item"
            >
              <i-select
                v-model="item.int_id"
                filterable
              >
                <i-option
                  v-for="(el, index) in branches"
                  :key="index"
                  :value="el.id"
                >
                  {{ el.name }}
                </i-option>
              </i-select>
              <img
                src="/img/integrations/arrows-left-right.svg"
                alt=""
                class="cursor"
              />
              <i-select
                v-model="item.ext_id"
                filterable
              >
                <i-option
                  v-for="(el, index) in mercury.ext_branches"
                  :key="index"
                  :value="el.id"
                >
                  {{ el.name }}
                </i-option>
              </i-select>
            </div>
          </div>
        </div>
        <div
          class="sidebar-block"
          style="border-bottom: 0 !important"
        >
          <div class="block-title">Для загрузки остатков по магазинам и новых ВСД, укажите данные из ВетИС.Паспорт</div>

          <label> Учетная запись (Account name) </label>
          <b-form-input
            v-model="account_name"
            class="mb-3"
            autocomplete="off"
          />
        </div>
      </div>

      <div v-if="is_registered === false">
        <div
          class="sidebar-block"
          style="border-bottom: 0 !important"
        >
          <div class="block-title pb-3">Для интеграции с системой выполните следующие шаги</div>
          <div style="display: flex; flex-direction: row">
            <div style="display: flex; flex-direction: column; align-items: center; justify-self: flex-start">
              <span class="round-div">1</span>
              <div class="line"></div>
              <span class="round-div">2</span>
              <div class="line"></div>
              <span class="round-div">3</span>
            </div>
            <div style="display: flex; flex-direction: column">
              <div
                style="display: flex; flex-direction: column; margin-bottom: 30px"
                class="pl-3"
              >
                <span
                  style="font-size: 16px"
                  class="pt-1"
                  >Оформите заявку для получения “Тестового контура”</span
                >
                <span style="color: #888888; margin-bottom: 40px"
                  >Данные из этого письма вам не пригодятся, просто удалите полученные письма</span
                >
                <b-button
                  variant="outline-primary"
                  class="w-50"
                >
                  <div
                    class="center p-4"
                    style="display: flex; flex-direction: row; justify-content: space-around; flex: 1; font-size: 16px"
                  >
                    <span>Оформить заявку</span>
                    <img src="/img/icons/url_icon.svg" />
                  </div>
                </b-button>
              </div>
              <div
                style="display: flex; flex-direction: column; margin-bottom: 30px"
                class="pl-3"
              >
                <span
                  style="font-size: 16px"
                  class="pt-1"
                  >Оформите заявку для получения “Продуктивного контура”</span
                >
                <span style="color: #888888; margin-bottom: 40px"
                  >Данные из этого письма пригодтся на следующем шаге</span
                >
                <b-button
                  variant="outline-primary"
                  class="w-50"
                >
                  <div
                    class="center p-4"
                    style="display: flex; flex-direction: row; justify-content: space-around; flex: 1; font-size: 16px"
                  >
                    <span>Оформить заявку</span>
                    <img src="/img/icons/url_icon.svg" />
                  </div>
                </b-button>
              </div>
              <div
                style="display: flex; flex-direction: column"
                class="pl-3"
              >
                <span
                  style="font-size: 16px; margin-bottom: 40px"
                  class="pt-1"
                  >Заполните полученные из почты данные</span
                >
                <b-button
                  variant="primary"
                  class="w-50"
                  @click="is_registered = true"
                >
                  <div
                    class="center p-4"
                    style="display: flex; flex-direction: row; justify-content: space-around; flex: 1; font-size: 16px"
                  >
                    <span>Продолжить</span>
                  </div>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <div
          v-if="is_registered"
          class="b-action-buttons"
        >
          <b-button
            variant="primary"
            class="cursor"
            :disabled="step === 1 && !account_name?.length"
            @click="updateIntegration"
          >
            Подключить
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-3"
            @click="step === 0 ? (is_registered = null) : step--"
          >
            Назад
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Mercury',
    props: {
      mercury: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        is_registered: null,
        step: 0,
        login: '',
        password: '',
        api_key: '',
        issuer_id: '',
        account_name: '',
        error: false,
        show_sb: false,
        branch_mapping: []
      }
    },
    async mounted() {},
    methods: {
      updateIntegration() {
        const cb = () => {
          if (this.step === 1) {
            this.show_sb = false
            this.$noty.show('Интеграция успешна')
            return
          }
          this.step++
          if (!this.mercury.items?.[0]?.branch_mapping?.length) {
            this.branch_mapping = this.mercury.ext_branches?.map((el) => {
              return {
                ext_id: el.id,
                int_id: ''
              }
            })
          }
          this.error = false
        }

        const err = () => {
          this.error = true
        }
        this.$emit(
          'update-integration',
          {
            id: this.mercury?.id ?? null,
            items: [
              {
                login: this.login,
                password: this.password,
                api_key: this.api_key,
                issuerID: this.issuer_id,
                branch_mapping: this.branch_mapping ?? [],
                account_name: this.account_name
              }
            ]
          },
          cb,
          err
        )
      },

      setInt() {
        this.show_sb = true
        if (this.mercury?.id) {
          this.is_registered = true
          this.step = 1
          this.login = this.mercury.items[0].login
          this.password = this.mercury.items[0].password
          this.api_key = this.mercury.items[0].api_key
          this.issuer_id = this.mercury.items[0].issuerID
          this.account_name = this.mercury.items[0].account_name
          this.branch_mapping =
            this.mercury.items[0].branch_mapping ??
            this.mercury.ext_branches?.map((el) => {
              return {
                ext_id: el.id,
                int_id: ''
              }
            })
        }
      },

      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      }
    },
    computed: {
      ...mapGetters({
        branches: 'settings/getBranchList'
      })
    }
  }
</script>

<style scoped lang="scss">
  #mercury-inner {
    .error-bottom {
      background-color: #e53835;
      color: white;
      font-size: 16px;
    }

    .line {
      margin-top: 8px;
      margin-bottom: 8px;
      width: 2px;
      height: 103px;
      background-color: #00a3ff;
    }

    .round-div {
      font-size: 18px;
      font-weight: 500;
      border: 2px solid #00a3ff;
      border-radius: 100%;
      display: inline-block;
      width: 32px;
      height: 32px;
      text-align: center;
    }

    #mercury-sidebar {
      width: 680px !important;

      .b-sidebar-header {
        img.close-sidebar {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 22px;
        }
      }

      .b-sidebar-footer {
        .b-action-buttons {
          padding: 0 22px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .b-sidebar-body {
        .sidebar-block {
          border-bottom: 1px solid #e1e1e1;
          padding: 22px;

          .block-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            color: #313131;
            margin-bottom: 16px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }
        }

        .info {
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          &__detail {
            background: #e6f0ff;
            border-radius: 4px;
            margin-top: 22px;
            border-left: 3px solid #b9d6ff;
            padding: 15px;

            img {
              margin-right: 15px;
            }
          }
        }

        .token {
          .text {
            img {
              margin-left: 5px;
            }
          }
        }

        .b-select {
          border: 1px solid #e1e1e1;
          border-radius: 4px;

          &__title {
            border-bottom: 1px solid #e1e1e1;
            display: flex;

            .item {
              width: 50%;
              padding: 8px 0;
              text-align: center;

              &:nth-child(2) {
                border-left: 1px solid #e1e1e1;
              }
            }
          }

          &__item {
            display: flex;
            padding: 16px;
            justify-content: space-between;
          }
        }
      }
    }
  }

  ::v-deep() {
    #mercury-sidebar {
      width: 680px !important;
      .b-sidebar-header {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 85px;
      }

      .b-sidebar-footer {
        position: relative;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        height: 65px;
        display: flex;
      }

      .b-sidebar-body {
        background: #fff;
        padding: 0;
        display: flex;
        flex-direction: column;

        a {
          border-bottom: 1px solid #0066ff;
          font-size: 16px;
        }
      }

      .info {
        a {
          color: #313131;
          font-weight: 600;
          border-bottom: 1px solid #313131;
        }
      }

      .token {
        .text {
          margin-top: 16px;
        }

        form {
          margin-top: 7px;

          input {
            height: 42px !important;
            border: 1px solid #bcbcbc;
            border-radius: 2px;
            font-size: 16px;
            color: #313131;
          }
        }
      }

      .b-select {
        &__item {
          input,
          .ivu-select {
            width: 282px;
          }
        }
      }
    }
  }
</style>
