<template>
  <section id="mercury-vsd">
    <!-- <h1>Меркурий</h1> -->

    <div class="tag-cont">
      <div class="active-vsd">Входящие ВСД</div>
      <div class="disabled-vsd">Исходящие ВСД</div>
      <div class="disabled-vsd">Перемещения</div>
    </div>

    <incoming-vsd v-if="active_tab === 0" />
  </section>
</template>

<script>
  import IncomingVsd from '@/views/external-systems/components/IncomingVsd'
  export default {
    name: 'Mercury',
    components: { IncomingVsd },
    data() {
      return {
        active_tab: 0
      }
    }
  }
</script>

<style scoped>
  .tag-cont {
    display: flex;
    flex-direction: row;
    /* margin-top: 14px; */
    margin-bottom: 22px;
    gap: 16px;
  }

  .active-vsd {
    background-color: #00a3ff;
    color: white;
    border-radius: 100px;
    padding: 4px 10px;
    line-height: 24px;
    cursor: pointer;
  }

  .disabled-vsd {
    background-color: #e1e1e1;
    color: #888888;
    border-radius: 100px;
    padding: 4px 10px;
    line-height: 24px;
  }
</style>
