<template>
  <section id="incoming-vsd">
    <mercury
      :mercury="mercury"
      @update-integration="addMercury"
    />
    <autofix-modal
      :mercury="mercury"
      @set-fix="setIntegration"
      @closeModal="closeAutofixModal"
    />
    <product-binding-modal
      :product="selectedBindingProduct"
      type="mercury"
      @bind="refresh"
    />
    <template v-if="mercury">
      <div style="display: flex; flex-direction: row; justify-content: space-between">
        <div
          style="display: flex; flex-direction: row; gap: 16px"
          class="mb-3"
        >
          <work-period @date_range="setDateRange" />

          <b-dropdown
            no-caret
            variant="light"
            size="sm"
          >
            <template #button-content>
              <span style="background: white; padding: 8px 10px; border-radius: 2px; border: 1px solid #bcbcbc"
                ><div style="display: flex; flex-direction: row; align-items: center">
                  {{ fixed === null ? 'Все типы гашения' : fixed ? 'Только погашенные' : 'Только непогашенные' }}
                  <img
                    src="/img/icons/arrow.svg"
                    alt="down"
                    style="transform: rotate(90deg)"
                    class="ml-2 mr-2"
                  />
                </div>
              </span>
            </template>
            <b-dropdown-item @click="fixed = null">
              <div style="display: flex; flex-direction: row; justify-content: space-between">
                Все типы гашения
                <img
                  v-if="fixed === null"
                  class="ml-2 mr-2"
                  src="/img/icons/checkmark_yes.svg"
                  alt="check"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="fixed = true">
              <div style="display: flex; flex-direction: row; justify-content: space-between">
                Только погашенные
                <img
                  v-if="fixed"
                  class="ml-2 mr-2"
                  src="/img/icons/checkmark_yes.svg"
                  alt="check"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="fixed = false">
              <div style="display: flex; flex-direction: row; justify-content: space-between">
                Только непогашенные
                <img
                  v-if="fixed === false"
                  class="ml-2 mr-2"
                  src="/img/icons/checkmark_yes.svg"
                  alt="check"
                />
              </div>
            </b-dropdown-item>
          </b-dropdown>

          <i-select
            v-model="selectedSupplier"
            filterable
            placeholder="Все поставщики"
            multiple
            :max-tag-count="1"
            :max-tag-placeholder="(val) => '+ Еще ' + val"
          >
            <i-option
              v-for="option of supplierList"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </i-option>
          </i-select>
          <!--        <i-select placeholder="Связанные и несвязанные" multiple>-->
          <!--          <i-option value="binded">Связанные</i-option>-->
          <!--          <i-option value="unbinded">Несвязанные</i-option>-->
          <!--        </i-select>-->
          <b-dropdown
            no-caret
            variant="light"
            size="sm"
          >
            <template #button-content>
              <span style="background: white; padding: 8px 10px; border-radius: 2px; border: 1px solid #bcbcbc"
                ><div style="display: flex; flex-direction: row; align-items: center">
                  {{
                    show === 'all'
                      ? 'Связанные и несвязанные'
                      : show === 'binded'
                      ? 'Только связанные'
                      : 'Только несвязанные'
                  }}
                  <img
                    src="/img/icons/arrow.svg"
                    alt="down"
                    style="transform: rotate(90deg)"
                    class="ml-2 mr-2"
                  />
                </div>
              </span>
            </template>
            <b-dropdown-item @click="show = 'all'">
              <div style="display: flex; flex-direction: row; justify-content: space-between">
                Связанные и несвязанные
                <img
                  v-if="show === 'all'"
                  class="ml-2 mr-2"
                  src="/img/icons/checkmark_yes.svg"
                  alt="check"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="show = 'binded'">
              <div style="display: flex; flex-direction: row; justify-content: space-between">
                Только связанные
                <img
                  v-if="show === 'binded'"
                  class="ml-2 mr-2"
                  src="/img/icons/checkmark_yes.svg"
                  alt="check"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="show = 'unbinded'">
              <div style="display: flex; flex-direction: row; justify-content: space-between">
                Только несвязанные
                <img
                  v-if="show === 'unbinded'"
                  class="ml-2 mr-2"
                  src="/img/icons/checkmark_yes.svg"
                  alt="check"
                />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <!--        <b-button-->
        <!--          variant="primary"-->
        <!--          @click="refresh"-->
        <!--          :disabled="$apollo.queries.DownloadVsd.loading"-->
        <!--        >-->
        <!--          <img src="/img/icons/refresh.svg" alt="Обновить" class="mr-2" />-->
        <!--          Обновить-->
        <!--        </b-button>-->
      </div>
      <div
        class="card p-0 m-0"
        style="height: 67vh; overflow: hidden"
      >
        <div
          style="display: flex; flex-direction: row; justify-content: space-between; align-items: center"
          class="p-3"
        >
          <div
            style="display: flex; flex-direction: row"
            class="w-75"
          >
            <b-form-input
              v-model="searchString"
              class="w-25"
              placeholder="Поиск"
            />
            <b-dropdown
              no-caret
              variant="none"
              class="ml-2"
            >
              <template #button-content>
                <div class="btn-more">
                  <img
                    src="/img/icons/settings.svg"
                    alt=""
                  />
                </div>
              </template>
              <b-dropdown-item @click="showSortModal"> Изменить столбцы </b-dropdown-item>
            </b-dropdown>
          </div>
          <div style="display: flex; flex-direction: row; align-items: center">
            Автогашение
            <span
              v-if="mercury?.settings?.auto_fix"
              class="ml-1"
            >
              через
              <span
                v-b-modal.autofix-modal
                class="ml-1"
                style="text-decoration: underline dotted; cursor: pointer"
                >{{ mercury?.settings?.auto_fix }} дней</span
              >
            </span>
            <b-checkbox
              v-model="autoFix"
              switch
              class="ml-2"
              @change="set_autofix"
            />
          </div>
        </div>
        <resizable-table
          :inside_card="false"
          :default_fields="fields"
          table_name="incoming_vsd"
          :items="vsds"
          :busy="$apollo.queries.MercuryVsds.loading"
        >
          <template #head_id>
            <b-checkbox
              v-model="select_all"
              @change="selectAll"
            />
          </template>

          <template #head_hide>
            <img src="/img/icons/connection.svg" />
          </template>

          <template #body_id="{ item }">
            <b-checkbox
              v-model="selected"
              :value="item"
              :checked="selected.some((el) => el.id === item.id)"
            />
          </template>

          <template #body_hide="{ item }">
            <binding-chain-btn
              :spec="item"
              type="mercury"
              :original_product="item?.product?.original_product"
              :product="item?.product?.product"
              :supplier_name="item?.supplier?.name"
              @click="showBindingModal(item)"
            />
          </template>

          <template #body_date="{ value }"> {{ formatDate(value) }}</template>

          <template #body_product="{ item }">
            <div
              v-if="!item.product.product"
              class="text-left"
            >
              {{ item.product.productItem_name }}
            </div>
            <div
              v-else
              class="text-left"
            >
              {{ item?.product?.product?.name }}
              <div class="b-name article">
                <div>{{ item?.product?.product?.articul }}</div>
                <div>{{ item?.product?.product?.supplier?.name }}</div>
              </div>
            </div>
          </template>

          <template #body_supplier="{ item }">
            <div class="b-supplier name cursor text-left">
              {{
                item.supplier?.full_name
                  ? item.supplier?.full_name
                  : item.supplier?.name
                  ? item.supplier?.name
                  : 'Не указан'
              }}
            </div>
            <div class="b-supplier info table-substr text-left">
              ИНН/КПП:
              <span class="ml-2">
                {{ item.supplier?.inn ? item.supplier.inn : '–' }}/
                {{ item.supplier?.kpp ? item.supplier.kpp : '–' }}
              </span>
            </div>
          </template>

          <template #body_quantity="{ item }">
            <div class="table-quantity-block">
              <table-row-input
                :class="{
                  'red-color': +item?.volume_fact !== +item.volume?.toFixed(2)
                }"
                :value="item.volume_fact"
                :fixed="false"
                @handle-input="(val) => setVolume(val, item)"
              />
              <div
                class="fraction"
                :class="{
                  'red-color': +item?.volume_fact !== +item.volume?.toFixed(2)
                }"
              >
                /
              </div>
              <div>{{ +item.volume?.toFixed(2) || 0 }}</div>
            </div>
          </template>

          <!-- <template #body_quantity="{ item }">-->
          <!--            <div-->
          <!--              style="-->
          <!--                display: flex;-->
          <!--                flex-direction: row;-->
          <!--                justify-content: space-around;-->
          <!--              "-->
          <!--              class="center"-->
          <!--            >-->
          <!--              <div-->
          <!--                style="-->
          <!--                  display: flex;-->
          <!--                  flex: 1;-->
          <!--                  justify-content: center;-->
          <!--                  align-items: center;-->
          <!--                  padding: 0;-->
          <!--                "-->
          <!--                class="center"-->
          <!--              >-->
          <!--                {{ item.volume }}-->
          <!--              </div>-->

          <!--              <div-->
          <!--                style="-->
          <!--                  display: flex;-->
          <!--                  flex: 1;-->
          <!--                  justify-content: center;-->
          <!--                  align-items: center;-->
          <!--                  padding: 0;-->
          <!--                "-->
          <!--                class="center"-->
          <!--              >-->
          <!--                <table-row-input-->
          <!--                  :value="item.volume_fact"-->
          <!--                  :fixed="false"-->
          <!--                  @handle-input="(val) => setVolume(val, item)"-->
          <!--                />-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </template> -->

          <template #body_measurement="{ item }">
            {{ item.product?.measurement?.name ?? item.measurement ?? '-' }}
          </template>

          <template #body_prod_date="{ item }">
            <span
              >{{
                item.dateOfProduction_second
                  ? formatDate(item.dateOfProduction_second)
                  : item.dateOfProduction_first
                  ? formatDate(item.dateOfProduction_first)
                  : '-'
              }}
            </span>
            <span style="color: #888888">{{ getProdHours(item) }}</span>
          </template>

          <template #body_exp_date="{ item }">
            <span>
              {{
                item.expiryDate_second
                  ? formatDate(item.expiryDate_second)
                  : item.expiryDate_first
                  ? formatDate(item.expiryDate_first)
                  : '-'
              }}
            </span>
            <span style="color: #888888">{{ getExpHours(item) }}</span>
          </template>

          <template #body_ttn_number="{ item }">
            {{ item.referencedDocument_number ?? '-' }}
          </template>

          <template #body_ttn_date="{ item }">
            {{ item.referencedDocument_date ? formatDate(item.referencedDocument_date) : '-' }}
          </template>

          <template #body_status="{ value }">
            <span
              :style="`color: ${
                value === 'CONFIRMED' ? '#E5B509' : value === 'ERROR' ? '#E53835' : '#00CB91'
              }; border: 1px solid ${
                value === 'CONFIRMED' ? '#E5B509' : value === 'ERROR' ? '#E53835' : '#00CB91'
              }; padding: 4px 12px; border-radius: 3px; white-space: nowrap;`"
            >
              {{ value === 'CONFIRMED' ? 'Ожидает обработки' : value === 'ERROR' ? 'Ошибка' : 'Обработан' }}
            </span>
          </template>
        </resizable-table>
        <mercury-navbar
          style="position: absolute; bottom: 5px; left: 15px; width: 98%"
          :items="selected"
          @bind="bindItem"
          @clear="clearSelected"
          @change_status="change_status"
        />
      </div>
      <pagination
        table_name="incoming_vsd"
        :total="total"
        @change_pagination="handlerPagination"
      />
    </template>
    <template v-else>
      <b-card
        no-body
        class="p-0 m-0"
        style="height: 75vh; display: flex; align-items: center; justify-content: center"
      >
        <div class="center">
          <img
            src="/img/integrations/mercury.svg"
            class="logo-shadow"
            alt=""
          />
          <h3 class="mt-2">Установите интеграцию, чтобы <br />получать данные из системы</h3>
          <b-button
            v-b-toggle.mercury-sidebar
            variant="primary"
            class="p-4 mt-3"
          >
            <span style="font-size: 16px">Установить</span>
          </b-button>
        </div>
      </b-card>
    </template>
  </section>
</template>

<script>
  import { mapActions } from 'vuex'
  import BindingChainBtn from '@/views/operational-processes/components/incoming/binding/BindingChainBtn.vue'
  import ResizableTable from '@/components/ResizableTable'
  import Pagination from '@/views/shared/components/table/Pagination'
  import WorkPeriod from '@/components/WorkPeriod'
  import ProductBindingModal from '@/views/operational-processes/components/incoming/BindingModal'
  import { IncomingVSDModel } from '@/models/IncomingVSD.model'
  import { mapGetters } from 'vuex'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput'
  import AutofixModal from '@/views/external-systems/components/AutofixModal'
  import MercuryNavbar from '@/views/external-systems/components/MercuryNavbar'
  import Mercury from '@/views/integrations/components/Mercury'
  import { EventBus } from '@/main'

  export default {
    name: 'IncomingVsd',
    components: {
      BindingChainBtn,
      Mercury,
      MercuryNavbar,
      AutofixModal,
      TableRowInput,
      ProductBindingModal,
      WorkPeriod,
      Pagination,
      ResizableTable
    },
    apollo: {
      // DownloadVsd: {
      //   query: require('../gql/mercury/downloadVsd.graphql'),
      //   fetchPolicy: 'no-cache',
      //   notifyOnNetworkStatusChange: true,
      //   result({ data }) {
      //   }
      // },
      Suppliers: {
        query: require('../../products/gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplierList = data.Suppliers ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      MercuryVsds: {
        query: require('../gql/mercury/mercuryVsd.graphql'),
        debounce: 500,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        result({ data }) {
          if (!data) return
          this.vsds = data?.MercuryVsds?.vsds?.map((el) => new IncomingVSDModel(el))
          this.total = data?.MercuryVsds?.total ?? 0
        },
        variables() {
          return {
            input: {
              branch: [this.currentBranch.id],
              period: { begin: this.period[0], end: this.period[1] },
              skip: this.pagination.skip,
              take: this.pagination.take,
              search: this.searchString,
              supplier: this.selectedSupplier,
              linked: this.show === 'all' ? null : this.show === 'binded',
              fixed: this.fixed
            }
          }
        }
      },
      AllIntegrations: {
        query: require('../../integrations/gql/getIntegrations.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.mercury = data?.AllIntegrations?.integrations?.find((el) => el.type === 'mercury') ?? null
          this.autoFix = !!this.mercury?.settings?.auto_fix
        }
      }
    },

    data() {
      return {
        selectedBindingProduct: null,
        autoFix: false,
        mercury: {},
        show: 'all',
        supplierList: [],
        selectedSupplier: [],
        fixed: false,
        searchString: '',
        fields: [
          { key: 'id', label: '', width: 45, checked: true },
          { key: 'number', label: '№', width: 45, checked: true },
          { key: 'hide', label: '', width: 45, checked: true },
          { key: 'date', label: 'Дата', width: 100, checked: true },
          { key: 'product', label: 'Наименование', width: 250, checked: true },
          { key: 'supplier', label: 'Поставщик', width: 220, checked: true },
          // {
          //   key: 'quantity',
          //   label: 'Количество',
          //   width: 150,
          //   checked: true,
          //   children: [
          //     { key: 'volume', label: 'Заявлено', checked: true },
          //     { key: 'volume_fact', label: 'Факт', checked: true }
          //   ]
          // },
          {
            key: 'quantity',
            label: 'Факт / заявл.',
            width: 150,
            checked: true
          },
          { key: 'measurement', label: 'Ед. изм.', width: 60, checked: true },
          {
            key: 'prod_date',
            label: 'Дата производства',
            width: 140,
            checked: true
          },
          {
            key: 'exp_date',
            label: 'Годен до',
            width: 120,
            checked: true
          },
          { key: 'ttn_number', label: '№ ТТН', width: 80, checked: true },
          { key: 'ttn_date', label: 'Дата ТТН', width: 80, checked: true },
          { key: 'status', label: 'Состояние', width: 200, checked: true }
        ],
        vsds: [],
        select_all: false,
        selected: [],
        period: [],
        pagination: {
          skip: 0,
          take: 20
        },
        total: 0
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    watch: {
      selected(newVal) {
        if (newVal.length !== this.vsds.length) this.select_all = false
      }
    },

    created() {
      EventBus.$on('refetchMercuryVsds', () => {
        this.refresh()
      })
    },

    beforeDestroy() {
      this.setBindingModal({})
    },

    destroyed() {
      EventBus.$off('refetchMercuryVsds', () => this.refresh())
    },

    methods: {
      ...mapActions({
        setBindingModal: 'core/setBindingModal'
      }),

      showBindingModal(item) {
        // this.selectedBindingProduct = item.product
        // this.$bvModal.show('product-binding-modal')

        this.selectedBindingProduct = item.product
        this.setBindingModal({ spec_id: item.id })
        this.$bvModal.show('core-binding-modal')
      },

      closeAutofixModal() {
        this.autoFix = false
      },

      async bindItem() {
        if (this.selected[0].product.product) {
          try {
            await this.$apollo.mutate({
              mutation: require('../../operational-processes/gql/mercury/linkMercuryProduct.graphql'),
              fetchPolicy: 'no-cache',
              variables: {
                ext: this.selected[0].product.id
              }
            })
            this.selected = []
            this.$apollo.queries.MercuryVsds.refetch()
          } catch (e) {
            console.error(e)
            this.$noty.error('Ошибка отвязки')
          }
        } else this.showBindingModal(this.selected[0])
      },
      showSortModal() {
        this.$bvModal.show('sort-table-modal')
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(date)
      },
      setDateRange(date) {
        this.period = date
        this.$apollo.queries.MercuryVsds.refresh()
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
        this.$apollo.queries.MercuryVsds.refetch()
      },
      async setVolume(val, item) {
        item.setVolume(val)

        try {
          await this.$apollo.mutate({
            mutation: require('../gql/mercury/updateVsd.graphql'),
            variables: {
              input: item.volume_input
            }
          })
        } catch (e) {
          console.error(e)
          this.$noty.error(e.graphQLErrors?.[0]?.extensions?.ru ?? 'Ошибка')
          this.$apollo.queries.MercuryVsds.refresh()
        }
      },
      async addMercury(int, cb, err) {
        try {
          await this.$apollo.mutate({
            mutation: require('../../integrations/gql/InitCrpTech.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              input: { ...int, type: 'mercury' }
            }
          })
          await this.$apollo.queries.AllIntegrations.refetch()
          cb()
        } catch (e) {
          console.error(e)
          err()
        }
      },
      set_autofix(val) {
        if (val) {
          this.$bvModal.show('autofix-modal')
        } else if (!val) {
          this.setIntegration(null)
        }
      },
      async setIntegration(val) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/mercury/updateIntegrationSettings.graphql'),
          variables: {
            input: {
              integration: this.mercury.id,
              auto_fix: val ?? null
            }
          }
        })

        if (data?.UpdateIntegrationSettings?.integration) {
          this.$bvModal.hide('autofix-modal')
          this.$apollo.queries.AllIntegrations.refetch()
        }
      },
      refresh() {
        this.$apollo.queries.MercuryVsds.refetch()
      },
      clearSelected() {
        this.selected = []
      },
      async change_status() {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('../gql/mercury/changeVsdStatus.graphql'),
            variables: {
              input: this.selected?.map((el) => el.id)
            }
          })
          if (data?.ChangeVsdStatus?.status) {
            this.$noty.show('ВСД погашен(-ы)')
            this.selected = []
            this.$apollo.queries.MercuryVsds.refresh()
          }
        } catch (e) {
          this.$noty.error('Ошибка гашения ВСД')
        }
      },
      selectAll(val) {
        if (val) this.selected = this.vsds
        else this.selected = []
      },
      getProdHours(item) {
        const date = item.dateOfProduction_second
          ? item.dateOfProduction_second
          : item.dateOfProduction_first
          ? item.dateOfProduction_first
          : null
        if (date) {
          const hours = date.getHours()
          const minutes = date.getMinutes().toString().padStart(2, '0')
          return hours + ':' + minutes
        }
        return ''
      },
      getExpHours(item) {
        const date = item.expiryDate_second
          ? item.expiryDate_second
          : item.expiryDate_first
          ? item.expiryDate_first
          : null
        if (date) {
          const hours = date.getHours()
          const minutes = date.getMinutes().toString().padStart(2, '0')
          return hours + ':' + minutes
        }
        return ''
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .dropdown {
    .btn {
      background-color: transparent;
    }
    .btn:hover {
      background-color: transparent;
    }
  }

  ::v-deep() {
    table {
      thead {
        tr {
          th {
            padding: 8px !important;
          }
        }
      }

      tbody {
        tr {
          cursor: pointer;

          th {
            border-bottom: 1px solid #e1e1e1;
            border-top: none;
            padding: 8px !important;
          }

          td {
            border-bottom: 1px solid #e1e1e1;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #e1e1e1;
            padding: 8px !important;

            div {
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  :deep .count {
    padding: 0px;
    width: 70px;
  }

  :deep .ivu-select-selection {
    border-radius: 2px;
  }

  :deep .content-button {
    height: 32px !important;
  }

  :deep .ivu-select-selection {
    input::-webkit-input-placeholder {
      color: var(--text-primary-gray-700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .red-color {
    color: red;
  }

  .fraction {
    font-weight: 600;
    line-height: 12px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .article {
    display: flex;
    gap: 5px;
    color: var(--text-secondary-gray-400, #888);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .table-quantity-block {
    display: flex;
    align-items: center;
  }

  .table-substr {
    font-size: 12px;
    color: #888888;
  }

  .logo-shadow {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
</style>
