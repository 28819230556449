<template>
  <div
    v-if="items.length > 0"
    class="b-table__navbar"
  >
    <img
      src="/img/icons/table/icon_checked.svg"
      alt=""
      class="cursor"
      @click="clear"
    />
    {{ items.length }}
    {{ item_name }}

    <div class="b-btns mt-0">
      <b-button
        variant="dark"
        @click="changeStatus"
      >
        Погасить
      </b-button>
      <b-button
        v-if="items.length === 1"
        variant="dark"
        @click="bind"
      >
        {{ items[0]?.product.product ? 'Отвязать' : 'Привязать' }}
      </b-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MercuryNavbar',
    props: {
      items: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      item_name() {
        if (this.items.length === 1) return 'Позиция'
        if (this.items.length > 1 && this.items.length < 5) return 'Позиции'
        return 'Позиций'
      }
    },
    methods: {
      clear() {
        this.$emit('clear')
      },
      changeStatus() {
        this.$emit('change_status')
      },
      bind() {
        this.$emit('bind')
      }
    }
  }
</script>

<style scoped></style>
