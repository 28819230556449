import { MercuryProductModel } from '@/models/mercury-product.model'
import { SupplierModel } from '@/models/supplier.model'

export class IncomingVSDModel {
  constructor(request = {}) {
    this.id = request?.id
    this.number = request?.number
    this.product = request?.product ? new MercuryProductModel(request?.product) : null
    this.branch = request?.branch
    this.supplier = request?.supplier ? new SupplierModel(request?.supplier) : null
    this.date = request?.date ? new Date(request?.date) : null
    this.volume = request?.volume
    this.volume_fact = request?.volume_fact
    this.measurement = request?.measurement
    this.dateOfProduction_first = request?.dateOfProduction_first ? new Date(request?.dateOfProduction_first) : null
    this.dateOfProduction_second = request?.dateOfProduction_second ? new Date(request?.dateOfProduction_second) : null
    this.expiryDate_first = request?.expiryDate_first ? new Date(request?.expiryDate_first) : null
    this.expiryDate_second = request?.expiryDate_second ? new Date(request?.expiryDate_second) : null
    this.referencedDocument_date = request?.referencedDocument_date ? new Date(request?.referencedDocument_date) : null
    this.referencedDocument_number = request?.referencedDocument_number
    this.status = request?.status
  }

  setVolume = (val) => {
    this.volume_fact = val
  }

  get volume_input() {
    return {
      id: this.id,
      volume_fact: this.volume_fact
    }
  }
}
