var render = function render(){
  var _vm$mercury$settings$, _vm$mercury, _vm$mercury$settings;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "autofix-modal",
      "centered": ""
    },
    on: {
      "hide": _vm.closeModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('h5', [_vm._v("Период гашения")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": _vm.hideModal
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function () {
        var _vm$period;

        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row",
            "flex": "1"
          }
        }, [_c('b-button', {
          staticClass: "m-0 p-0",
          staticStyle: {
            "flex": "1"
          },
          attrs: {
            "variant": "primary",
            "disabled": !((_vm$period = _vm.period) !== null && _vm$period !== void 0 && _vm$period.length)
          },
          on: {
            "click": _vm.setFix
          }
        }, [_c('span', {
          staticClass: "m-2"
        }, [_vm._v("Сохранить")])])], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "ext-caption mb-3"
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/info_blue.svg"
    }
  }), _c('span', {
    staticClass: "text-dark"
  }, [_vm._v("Укажите через какой период автоматически гасить ")])]), _c('label', {
    attrs: {
      "for": "period-input"
    }
  }, [_vm._v("Период, дней")]), _c('b-form-input', {
    attrs: {
      "id": "period-input",
      "value": (_vm$mercury$settings$ = (_vm$mercury = _vm.mercury) === null || _vm$mercury === void 0 ? void 0 : (_vm$mercury$settings = _vm$mercury.settings) === null || _vm$mercury$settings === void 0 ? void 0 : _vm$mercury$settings.auto_fix) !== null && _vm$mercury$settings$ !== void 0 ? _vm$mercury$settings$ : '',
      "type": "number"
    },
    on: {
      "input": _vm.setInput
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }