var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "mercury-inner"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "mercury-sidebar",
      "title": "Меркурий",
      "right": "",
      "backdrop": "",
      "shadow": "",
      "visible": _vm.show_sb
    },
    on: {
      "shown": _vm.setInt
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_vm._v(" Меркурий "), _c('img', {
          staticClass: "close-sidebar cursor",
          attrs: {
            "src": "/img/integrations/icon-sidebar-close.svg",
            "alt": ""
          },
          on: {
            "click": hide
          }
        })];
      }
    }, {
      key: "footer",
      fn: function () {
        var _vm$account_name;

        return [_vm.is_registered ? _c('div', {
          staticClass: "b-action-buttons"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "variant": "primary",
            "disabled": _vm.step === 1 && !((_vm$account_name = _vm.account_name) !== null && _vm$account_name !== void 0 && _vm$account_name.length)
          },
          on: {
            "click": _vm.updateIntegration
          }
        }, [_vm._v(" Подключить ")]), _c('b-button', {
          staticClass: "cursor ml-3",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              _vm.step === 0 ? _vm.is_registered = null : _vm.step--;
            }
          }
        }, [_vm._v(" Назад ")])], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.show_sb,
      callback: function ($$v) {
        _vm.show_sb = $$v;
      },
      expression: "show_sb"
    }
  }, [_vm.is_registered === null ? _c('div', {
    staticClass: "sidebar-block info"
  }, [_c('p', [_vm._v(" Система предназначена для электронной сертификации и обеспечения прослеживаемости поднадзорных государственному ветеринарному надзору грузов при их производстве, обороте и перемещении по территории Российской Федерации в целях создания единой информационной среды для ветеринарии, повышения биологической и пищевой безопасности. ")]), _c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _c('router-link', {
    attrs: {
      "to": "#"
    }
  }, [_vm._v("Подробная справка")]), _vm._v(" по интеграции ")], 1)]) : _vm._e(), _vm.is_registered === null ? _c('div', {
    staticClass: "token"
  }, [_c('div', {
    staticClass: "center p-5"
  }, [_c('h4', {
    staticClass: "pb-0 mb-0"
  }, [_vm._v("Вы зарегистрированы в системе “Меркурий”?")])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('b-button', {
    staticClass: "cursor p-4",
    staticStyle: {
      "font-size": "16px"
    },
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.is_registered = true;
      }
    }
  }, [_vm._v(" Да, есть аккаунт ")]), _c('b-button', {
    staticClass: "cursor ml-3 p-4",
    staticStyle: {
      "font-size": "16px"
    },
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        _vm.is_registered = false;
      }
    }
  }, [_vm._v(" Нет аккаунта ")])], 1)]) : _vm._e(), _vm.is_registered && _vm.step === 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "flex": "1"
    }
  }, [_c('h5', {
    staticClass: "p-4 mb-0"
  }, [_vm._v(" Укажите данные Ветис.API которые вам приходили по Email с адреса no-reply@vetrf.ru от системы Меркурий ")]), _c('div', {
    staticClass: "p-3",
    staticStyle: {
      "padding-top": "0 !important"
    }
  }, [_c('label', [_vm._v(" Login ")]), _c('b-form-input', {
    staticClass: "mb-3",
    attrs: {
      "type": "text",
      "autocomplete": "new-password"
    },
    model: {
      value: _vm.login,
      callback: function ($$v) {
        _vm.login = $$v;
      },
      expression: "login"
    }
  }), _c('label', [_vm._v(" Password ")]), _c('b-form-input', {
    staticClass: "mb-3",
    attrs: {
      "type": "password",
      "autocomplete": "new-password"
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('label', [_vm._v(" APIkey ")]), _c('b-form-input', {
    staticClass: "mb-3",
    model: {
      value: _vm.api_key,
      callback: function ($$v) {
        _vm.api_key = $$v;
      },
      expression: "api_key"
    }
  }), _c('label', [_vm._v(" IssuerID ")]), _c('b-form-input', {
    staticClass: "mb-3",
    model: {
      value: _vm.issuer_id,
      callback: function ($$v) {
        _vm.issuer_id = $$v;
      },
      expression: "issuer_id"
    }
  })], 1), _vm.error ? _c('div', {
    staticClass: "mt-auto p-3 error-bottom"
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/warning-white.svg",
      "alt": "warn"
    }
  }), _vm._v(" Ошибка при подключении ")]) : _vm._e()]) : _vm._e(), _vm.step === 1 ? _c('div', [_c('div', {
    staticClass: "sidebar-block"
  }, [_c('div', {
    staticClass: "block-title"
  }, [_vm._v("Сопоставьте магазины Энтерсайт с магазинами Меркурий")]), _c('div', {
    staticClass: "b-select"
  }, [_c('div', {
    staticClass: "b-select__title"
  }, [_c('div', {
    staticClass: "item"
  }, [_vm._v("Магазин Энтерсайт")]), _c('div', {
    staticClass: "item"
  }, [_vm._v("Магазин Меркурий")])]), _vm._l(_vm.branch_mapping, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "b-select__item"
    }, [_c('i-select', {
      attrs: {
        "filterable": ""
      },
      model: {
        value: item.int_id,
        callback: function ($$v) {
          _vm.$set(item, "int_id", $$v);
        },
        expression: "item.int_id"
      }
    }, _vm._l(_vm.branches, function (el, index) {
      return _c('i-option', {
        key: index,
        attrs: {
          "value": el.id
        }
      }, [_vm._v(" " + _vm._s(el.name) + " ")]);
    }), 1), _c('img', {
      staticClass: "cursor",
      attrs: {
        "src": "/img/integrations/arrows-left-right.svg",
        "alt": ""
      }
    }), _c('i-select', {
      attrs: {
        "filterable": ""
      },
      model: {
        value: item.ext_id,
        callback: function ($$v) {
          _vm.$set(item, "ext_id", $$v);
        },
        expression: "item.ext_id"
      }
    }, _vm._l(_vm.mercury.ext_branches, function (el, index) {
      return _c('i-option', {
        key: index,
        attrs: {
          "value": el.id
        }
      }, [_vm._v(" " + _vm._s(el.name) + " ")]);
    }), 1)], 1);
  })], 2)]), _c('div', {
    staticClass: "sidebar-block",
    staticStyle: {
      "border-bottom": "0 !important"
    }
  }, [_c('div', {
    staticClass: "block-title"
  }, [_vm._v("Для загрузки остатков по магазинам и новых ВСД, укажите данные из ВетИС.Паспорт")]), _c('label', [_vm._v(" Учетная запись (Account name) ")]), _c('b-form-input', {
    staticClass: "mb-3",
    attrs: {
      "autocomplete": "off"
    },
    model: {
      value: _vm.account_name,
      callback: function ($$v) {
        _vm.account_name = $$v;
      },
      expression: "account_name"
    }
  })], 1)]) : _vm._e(), _vm.is_registered === false ? _c('div', [_c('div', {
    staticClass: "sidebar-block",
    staticStyle: {
      "border-bottom": "0 !important"
    }
  }, [_c('div', {
    staticClass: "block-title pb-3"
  }, [_vm._v("Для интеграции с системой выполните следующие шаги")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center",
      "justify-self": "flex-start"
    }
  }, [_c('span', {
    staticClass: "round-div"
  }, [_vm._v("1")]), _c('div', {
    staticClass: "line"
  }), _c('span', {
    staticClass: "round-div"
  }, [_vm._v("2")]), _c('div', {
    staticClass: "line"
  }), _c('span', {
    staticClass: "round-div"
  }, [_vm._v("3")])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('div', {
    staticClass: "pl-3",
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "margin-bottom": "30px"
    }
  }, [_c('span', {
    staticClass: "pt-1",
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("Оформите заявку для получения “Тестового контура”")]), _c('span', {
    staticStyle: {
      "color": "#888888",
      "margin-bottom": "40px"
    }
  }, [_vm._v("Данные из этого письма вам не пригодятся, просто удалите полученные письма")]), _c('b-button', {
    staticClass: "w-50",
    attrs: {
      "variant": "outline-primary"
    }
  }, [_c('div', {
    staticClass: "center p-4",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-around",
      "flex": "1",
      "font-size": "16px"
    }
  }, [_c('span', [_vm._v("Оформить заявку")]), _c('img', {
    attrs: {
      "src": "/img/icons/url_icon.svg"
    }
  })])])], 1), _c('div', {
    staticClass: "pl-3",
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "margin-bottom": "30px"
    }
  }, [_c('span', {
    staticClass: "pt-1",
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("Оформите заявку для получения “Продуктивного контура”")]), _c('span', {
    staticStyle: {
      "color": "#888888",
      "margin-bottom": "40px"
    }
  }, [_vm._v("Данные из этого письма пригодтся на следующем шаге")]), _c('b-button', {
    staticClass: "w-50",
    attrs: {
      "variant": "outline-primary"
    }
  }, [_c('div', {
    staticClass: "center p-4",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-around",
      "flex": "1",
      "font-size": "16px"
    }
  }, [_c('span', [_vm._v("Оформить заявку")]), _c('img', {
    attrs: {
      "src": "/img/icons/url_icon.svg"
    }
  })])])], 1), _c('div', {
    staticClass: "pl-3",
    staticStyle: {
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('span', {
    staticClass: "pt-1",
    staticStyle: {
      "font-size": "16px",
      "margin-bottom": "40px"
    }
  }, [_vm._v("Заполните полученные из почты данные")]), _c('b-button', {
    staticClass: "w-50",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.is_registered = true;
      }
    }
  }, [_c('div', {
    staticClass: "center p-4",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-around",
      "flex": "1",
      "font-size": "16px"
    }
  }, [_c('span', [_vm._v("Продолжить")])])])], 1)])])])]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }