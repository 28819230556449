var render = function render(){
  var _vm$mercury, _vm$mercury$settings, _vm$mercury2, _vm$mercury2$settings;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "incoming-vsd"
    }
  }, [_c('mercury', {
    attrs: {
      "mercury": _vm.mercury
    },
    on: {
      "update-integration": _vm.addMercury
    }
  }), _c('autofix-modal', {
    attrs: {
      "mercury": _vm.mercury
    },
    on: {
      "set-fix": _vm.setIntegration,
      "closeModal": _vm.closeAutofixModal
    }
  }), _c('product-binding-modal', {
    attrs: {
      "product": _vm.selectedBindingProduct,
      "type": "mercury"
    },
    on: {
      "bind": _vm.refresh
    }
  }), _vm.mercury ? [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-between"
    }
  }, [_c('div', {
    staticClass: "mb-3",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "gap": "16px"
    }
  }, [_c('work-period', {
    on: {
      "date_range": _vm.setDateRange
    }
  }), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "light",
      "size": "sm"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticStyle: {
            "background": "white",
            "padding": "8px 10px",
            "border-radius": "2px",
            "border": "1px solid #bcbcbc"
          }
        }, [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.fixed === null ? 'Все типы гашения' : _vm.fixed ? 'Только погашенные' : 'Только непогашенные') + " "), _c('img', {
          staticClass: "ml-2 mr-2",
          staticStyle: {
            "transform": "rotate(90deg)"
          },
          attrs: {
            "src": "/img/icons/arrow.svg",
            "alt": "down"
          }
        })])])];
      },
      proxy: true
    }], null, false, 1943903308)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.fixed = null;
      }
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-between"
    }
  }, [_vm._v(" Все типы гашения "), _vm.fixed === null ? _c('img', {
    staticClass: "ml-2 mr-2",
    attrs: {
      "src": "/img/icons/checkmark_yes.svg",
      "alt": "check"
    }
  }) : _vm._e()])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.fixed = true;
      }
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-between"
    }
  }, [_vm._v(" Только погашенные "), _vm.fixed ? _c('img', {
    staticClass: "ml-2 mr-2",
    attrs: {
      "src": "/img/icons/checkmark_yes.svg",
      "alt": "check"
    }
  }) : _vm._e()])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.fixed = false;
      }
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-between"
    }
  }, [_vm._v(" Только непогашенные "), _vm.fixed === false ? _c('img', {
    staticClass: "ml-2 mr-2",
    attrs: {
      "src": "/img/icons/checkmark_yes.svg",
      "alt": "check"
    }
  }) : _vm._e()])])], 1), _c('i-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Все поставщики",
      "multiple": "",
      "max-tag-count": 1,
      "max-tag-placeholder": function (val) {
        return '+ Еще ' + val;
      }
    },
    model: {
      value: _vm.selectedSupplier,
      callback: function ($$v) {
        _vm.selectedSupplier = $$v;
      },
      expression: "selectedSupplier"
    }
  }, _vm._l(_vm.supplierList, function (option) {
    return _c('i-option', {
      key: option.id,
      attrs: {
        "value": option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "light",
      "size": "sm"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticStyle: {
            "background": "white",
            "padding": "8px 10px",
            "border-radius": "2px",
            "border": "1px solid #bcbcbc"
          }
        }, [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "row",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.show === 'all' ? 'Связанные и несвязанные' : _vm.show === 'binded' ? 'Только связанные' : 'Только несвязанные') + " "), _c('img', {
          staticClass: "ml-2 mr-2",
          staticStyle: {
            "transform": "rotate(90deg)"
          },
          attrs: {
            "src": "/img/icons/arrow.svg",
            "alt": "down"
          }
        })])])];
      },
      proxy: true
    }], null, false, 3784732359)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.show = 'all';
      }
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-between"
    }
  }, [_vm._v(" Связанные и несвязанные "), _vm.show === 'all' ? _c('img', {
    staticClass: "ml-2 mr-2",
    attrs: {
      "src": "/img/icons/checkmark_yes.svg",
      "alt": "check"
    }
  }) : _vm._e()])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.show = 'binded';
      }
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-between"
    }
  }, [_vm._v(" Только связанные "), _vm.show === 'binded' ? _c('img', {
    staticClass: "ml-2 mr-2",
    attrs: {
      "src": "/img/icons/checkmark_yes.svg",
      "alt": "check"
    }
  }) : _vm._e()])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.show = 'unbinded';
      }
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-between"
    }
  }, [_vm._v(" Только несвязанные "), _vm.show === 'unbinded' ? _c('img', {
    staticClass: "ml-2 mr-2",
    attrs: {
      "src": "/img/icons/checkmark_yes.svg",
      "alt": "check"
    }
  }) : _vm._e()])])], 1)], 1)]), _c('div', {
    staticClass: "card p-0 m-0",
    staticStyle: {
      "height": "67vh",
      "overflow": "hidden"
    }
  }, [_c('div', {
    staticClass: "p-3",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "w-75",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row"
    }
  }, [_c('b-form-input', {
    staticClass: "w-25",
    attrs: {
      "placeholder": "Поиск"
    },
    model: {
      value: _vm.searchString,
      callback: function ($$v) {
        _vm.searchString = $$v;
      },
      expression: "searchString"
    }
  }), _c('b-dropdown', {
    staticClass: "ml-2",
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }], null, false, 1204376047)
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.showSortModal
    }
  }, [_vm._v(" Изменить столбцы ")])], 1)], 1), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "align-items": "center"
    }
  }, [_vm._v(" Автогашение "), (_vm$mercury = _vm.mercury) !== null && _vm$mercury !== void 0 && (_vm$mercury$settings = _vm$mercury.settings) !== null && _vm$mercury$settings !== void 0 && _vm$mercury$settings.auto_fix ? _c('span', {
    staticClass: "ml-1"
  }, [_vm._v(" через "), _c('span', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.autofix-modal",
      modifiers: {
        "autofix-modal": true
      }
    }],
    staticClass: "ml-1",
    staticStyle: {
      "text-decoration": "underline dotted",
      "cursor": "pointer"
    }
  }, [_vm._v(_vm._s((_vm$mercury2 = _vm.mercury) === null || _vm$mercury2 === void 0 ? void 0 : (_vm$mercury2$settings = _vm$mercury2.settings) === null || _vm$mercury2$settings === void 0 ? void 0 : _vm$mercury2$settings.auto_fix) + " дней")])]) : _vm._e(), _c('b-checkbox', {
    staticClass: "ml-2",
    attrs: {
      "switch": ""
    },
    on: {
      "change": _vm.set_autofix
    },
    model: {
      value: _vm.autoFix,
      callback: function ($$v) {
        _vm.autoFix = $$v;
      },
      expression: "autoFix"
    }
  })], 1)]), _c('resizable-table', {
    attrs: {
      "inside_card": false,
      "default_fields": _vm.fields,
      "table_name": "incoming_vsd",
      "items": _vm.vsds,
      "busy": _vm.$apollo.queries.MercuryVsds.loading
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('b-checkbox', {
          on: {
            "change": _vm.selectAll
          },
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "head_hide",
      fn: function () {
        return [_c('img', {
          attrs: {
            "src": "/img/icons/connection.svg"
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-checkbox', {
          attrs: {
            "value": item,
            "checked": _vm.selected.some(function (el) {
              return el.id === item.id;
            })
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }, {
      key: "body_hide",
      fn: function (_ref2) {
        var _item$product, _item$product2, _item$supplier;

        var item = _ref2.item;
        return [_c('binding-chain-btn', {
          attrs: {
            "spec": item,
            "type": "mercury",
            "original_product": item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : _item$product.original_product,
            "product": item === null || item === void 0 ? void 0 : (_item$product2 = item.product) === null || _item$product2 === void 0 ? void 0 : _item$product2.product,
            "supplier_name": item === null || item === void 0 ? void 0 : (_item$supplier = item.supplier) === null || _item$supplier === void 0 ? void 0 : _item$supplier.name
          },
          on: {
            "click": function ($event) {
              return _vm.showBindingModal(item);
            }
          }
        })];
      }
    }, {
      key: "body_date",
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(_vm.formatDate(value)))];
      }
    }, {
      key: "body_product",
      fn: function (_ref4) {
        var _item$product3, _item$product3$produc, _item$product4, _item$product4$produc, _item$product5, _item$product5$produc, _item$product5$produc2;

        var item = _ref4.item;
        return [!item.product.product ? _c('div', {
          staticClass: "text-left"
        }, [_vm._v(" " + _vm._s(item.product.productItem_name) + " ")]) : _c('div', {
          staticClass: "text-left"
        }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$product3 = item.product) === null || _item$product3 === void 0 ? void 0 : (_item$product3$produc = _item$product3.product) === null || _item$product3$produc === void 0 ? void 0 : _item$product3$produc.name) + " "), _c('div', {
          staticClass: "b-name article"
        }, [_c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$product4 = item.product) === null || _item$product4 === void 0 ? void 0 : (_item$product4$produc = _item$product4.product) === null || _item$product4$produc === void 0 ? void 0 : _item$product4$produc.articul))]), _c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$product5 = item.product) === null || _item$product5 === void 0 ? void 0 : (_item$product5$produc = _item$product5.product) === null || _item$product5$produc === void 0 ? void 0 : (_item$product5$produc2 = _item$product5$produc.supplier) === null || _item$product5$produc2 === void 0 ? void 0 : _item$product5$produc2.name))])])])];
      }
    }, {
      key: "body_supplier",
      fn: function (_ref5) {
        var _item$supplier2, _item$supplier3, _item$supplier4, _item$supplier5, _item$supplier6, _item$supplier7;

        var item = _ref5.item;
        return [_c('div', {
          staticClass: "b-supplier name cursor text-left"
        }, [_vm._v(" " + _vm._s((_item$supplier2 = item.supplier) !== null && _item$supplier2 !== void 0 && _item$supplier2.full_name ? (_item$supplier3 = item.supplier) === null || _item$supplier3 === void 0 ? void 0 : _item$supplier3.full_name : (_item$supplier4 = item.supplier) !== null && _item$supplier4 !== void 0 && _item$supplier4.name ? (_item$supplier5 = item.supplier) === null || _item$supplier5 === void 0 ? void 0 : _item$supplier5.name : 'Не указан') + " ")]), _c('div', {
          staticClass: "b-supplier info table-substr text-left"
        }, [_vm._v(" ИНН/КПП: "), _c('span', {
          staticClass: "ml-2"
        }, [_vm._v(" " + _vm._s((_item$supplier6 = item.supplier) !== null && _item$supplier6 !== void 0 && _item$supplier6.inn ? item.supplier.inn : '–') + "/ " + _vm._s((_item$supplier7 = item.supplier) !== null && _item$supplier7 !== void 0 && _item$supplier7.kpp ? item.supplier.kpp : '–') + " ")])])];
      }
    }, {
      key: "body_quantity",
      fn: function (_ref6) {
        var _item$volume, _item$volume2, _item$volume3;

        var item = _ref6.item;
        return [_c('div', {
          staticClass: "table-quantity-block"
        }, [_c('table-row-input', {
          class: {
            'red-color': +(item === null || item === void 0 ? void 0 : item.volume_fact) !== +((_item$volume = item.volume) === null || _item$volume === void 0 ? void 0 : _item$volume.toFixed(2))
          },
          attrs: {
            "value": item.volume_fact,
            "fixed": false
          },
          on: {
            "handle-input": function (val) {
              return _vm.setVolume(val, item);
            }
          }
        }), _c('div', {
          staticClass: "fraction",
          class: {
            'red-color': +(item === null || item === void 0 ? void 0 : item.volume_fact) !== +((_item$volume2 = item.volume) === null || _item$volume2 === void 0 ? void 0 : _item$volume2.toFixed(2))
          }
        }, [_vm._v(" / ")]), _c('div', [_vm._v(_vm._s(+((_item$volume3 = item.volume) === null || _item$volume3 === void 0 ? void 0 : _item$volume3.toFixed(2)) || 0))])], 1)];
      }
    }, {
      key: "body_measurement",
      fn: function (_ref7) {
        var _ref8, _item$product$measure, _item$product6, _item$product6$measur;

        var item = _ref7.item;
        return [_vm._v(" " + _vm._s((_ref8 = (_item$product$measure = (_item$product6 = item.product) === null || _item$product6 === void 0 ? void 0 : (_item$product6$measur = _item$product6.measurement) === null || _item$product6$measur === void 0 ? void 0 : _item$product6$measur.name) !== null && _item$product$measure !== void 0 ? _item$product$measure : item.measurement) !== null && _ref8 !== void 0 ? _ref8 : '-') + " ")];
      }
    }, {
      key: "body_prod_date",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('span', [_vm._v(_vm._s(item.dateOfProduction_second ? _vm.formatDate(item.dateOfProduction_second) : item.dateOfProduction_first ? _vm.formatDate(item.dateOfProduction_first) : '-') + " ")]), _c('span', {
          staticStyle: {
            "color": "#888888"
          }
        }, [_vm._v(_vm._s(_vm.getProdHours(item)))])];
      }
    }, {
      key: "body_exp_date",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('span', [_vm._v(" " + _vm._s(item.expiryDate_second ? _vm.formatDate(item.expiryDate_second) : item.expiryDate_first ? _vm.formatDate(item.expiryDate_first) : '-') + " ")]), _c('span', {
          staticStyle: {
            "color": "#888888"
          }
        }, [_vm._v(_vm._s(_vm.getExpHours(item)))])];
      }
    }, {
      key: "body_ttn_number",
      fn: function (_ref11) {
        var _item$referencedDocum;

        var item = _ref11.item;
        return [_vm._v(" " + _vm._s((_item$referencedDocum = item.referencedDocument_number) !== null && _item$referencedDocum !== void 0 ? _item$referencedDocum : '-') + " ")];
      }
    }, {
      key: "body_ttn_date",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.referencedDocument_date ? _vm.formatDate(item.referencedDocument_date) : '-') + " ")];
      }
    }, {
      key: "body_status",
      fn: function (_ref13) {
        var value = _ref13.value;
        return [_c('span', {
          style: `color: ${value === 'CONFIRMED' ? '#E5B509' : value === 'ERROR' ? '#E53835' : '#00CB91'}; border: 1px solid ${value === 'CONFIRMED' ? '#E5B509' : value === 'ERROR' ? '#E53835' : '#00CB91'}; padding: 4px 12px; border-radius: 3px; white-space: nowrap;`
        }, [_vm._v(" " + _vm._s(value === 'CONFIRMED' ? 'Ожидает обработки' : value === 'ERROR' ? 'Ошибка' : 'Обработан') + " ")])];
      }
    }], null, false, 2312635456)
  }), _c('mercury-navbar', {
    staticStyle: {
      "position": "absolute",
      "bottom": "5px",
      "left": "15px",
      "width": "98%"
    },
    attrs: {
      "items": _vm.selected
    },
    on: {
      "bind": _vm.bindItem,
      "clear": _vm.clearSelected,
      "change_status": _vm.change_status
    }
  })], 1), _c('pagination', {
    attrs: {
      "table_name": "incoming_vsd",
      "total": _vm.total
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })] : [_c('b-card', {
    staticClass: "p-0 m-0",
    staticStyle: {
      "height": "75vh",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "center"
  }, [_c('img', {
    staticClass: "logo-shadow",
    attrs: {
      "src": "/img/integrations/mercury.svg",
      "alt": ""
    }
  }), _c('h3', {
    staticClass: "mt-2"
  }, [_vm._v("Установите интеграцию, чтобы "), _c('br'), _vm._v("получать данные из системы")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.mercury-sidebar",
      modifiers: {
        "mercury-sidebar": true
      }
    }],
    staticClass: "p-4 mt-3",
    attrs: {
      "variant": "primary"
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "16px"
    }
  }, [_vm._v("Установить")])])], 1)])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }