import { render, staticRenderFns } from "./AutofixModal.vue?vue&type=template&id=318e379c&scoped=true&"
import script from "./AutofixModal.vue?vue&type=script&lang=js&"
export * from "./AutofixModal.vue?vue&type=script&lang=js&"
import style0 from "./AutofixModal.vue?vue&type=style&index=0&id=318e379c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318e379c",
  null
  
)

export default component.exports